import { ASSIGNMENT_TYPES } from '../constants';
import { updateAssignment } from '../services/assignments';

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const deleteLocalStorage = key => {
  localStorage.removeItem(key);
};

export const getLocalStorage = key => {
  const data = localStorage.getItem(key);
  return data;
};

export const updateAssignmentStatus = async (assignment, user, status, confirmStatus) => {
  if (!assignment) return;
  let body = { ...assignment, student: user.id };
  if (assignment.type === ASSIGNMENT_TYPES.INDIVIDUAL) {
    if (confirmStatus && assignment.status !== confirmStatus) return (body = null);
    body.status = status;
  } else {
    const { status: currentStatus } = assignment.group.filter(asgn => asgn.student._id === user.id)[0];
    if (confirmStatus && currentStatus !== confirmStatus) return (body = null);
    const filtredGroup = assignment.group.filter(asgn => asgn.student._id !== user.id);
    filtredGroup.push({ status, student: { _id: user.id, name: user.name } });
    body.group = filtredGroup;
  }
  if (body) {
    return await updateAssignment(assignment.id, body);
  }
  return await Promise.reject(null);
};

export const getStudentStatus = (assignment, userId) => {
  if (!assignment || !userId) return 'Open';
  if (assignment.type === ASSIGNMENT_TYPES.INDIVIDUAL) return assignment.status;
  return assignment.group.filter(item => item.student._id === userId)[0].status;
};
