import axios from 'axios';
import qs from 'query-string';

export const getBackpacks = async query => {
  try {
    const response = await axios.get(qs.stringifyUrl({ url: '/backpacks', query: { ...query, limit: 100 } }));
    return response.data;
  } catch (error) {
    // TODO handle error
  }
};

export const getBackpackById = async (id, config) => {
  try {
    const response = await axios.get(`/backpacks/${id}/`, config);
    return response.data;
  } catch (error) {
    // TODO handle error
    console.log('error', error);
  }
};

export const updateBackpack = async (id, backpack) => {
  const res = await axios.put(`/backpacks/${id}`, backpack);

  return res.data;
};
