import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from '../translations';
import { getLocalStorage } from './userService';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translations.en
    },
    no: {
      translation: translations.no
    },
    nb: {
      translation: translations.no
    }
  },
  lng: getLocalStorage('lng') || 'no',
  fallbackLng: 'no',
  keySeparator: false
});

export default i18n;
