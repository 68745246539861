import axios from 'axios';
import qs from 'query-string';

const defaultQueryConfig = {
  limit: 20,
  page: 1
};

export const getAssignments = async (queryConfig = {}) => {
  const config = {
    ...defaultQueryConfig,
    ...queryConfig,
    title: queryConfig.search || ''
  };
  const parseConfig = cfg =>
    Object.keys(cfg)
      .filter(key => config[key] !== '' && key !== 'search')
      .reduce((acc, key) => ({ ...acc, [key]: config[key] }), {});

  const url = qs.stringifyUrl({
    url: '/assignments',
    query: parseConfig(config)
  });

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    // TODO handle error
    console.log('error', error);
  }
};

export const updateAssignment = (id, body) => {
  return fetch(`${process.env.REACT_APP_API_URL}/assignments/${id}/`, {
    method: 'PUT',
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify(body)
  }).then(response => response.json());
};

export const getAssignmentById = async id => {
  try {
    const response = await axios.get(`/assignments/${id}/`);
    return response.data;
  } catch (error) {
    // TODO handle error
    console.log('error', error);
  }
};

export const createAssignment = async body => {
  const response = await axios.post('/assignments', body);

  return response.data;
};

export const getReportsForAssignment = async (assignmentId, query = {}) => {
  const params = { assignment: assignmentId, ...query };
  try {
    const response = await axios.get('/reports', { params });
    return response.data;
  } catch (error) {
    // TODO handle error
    console.log('error', error);
  }
};

export const getAssignmentForUser = async (userId, query = {}) => {
  const params = { student: userId, ...query };
  try {
    const response = await axios.get('/assignments', { params });
    return response.data;
  } catch (error) {
    // TODO handle error
    console.log('error', error);
  }
};
