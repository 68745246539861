import { createSlice } from '@reduxjs/toolkit';

export const redirectActiveTab = createSlice({
  name: 'redirectActiveTab',
  initialState: {
    redirectActiveTab: null
  },
  reducers: {
    setRedirectActiveTab: (state, action) => {
      state.redirectActiveTab = action.payload;
    }
  }
});

export const { setRedirectActiveTab } = redirectActiveTab.actions;

export default redirectActiveTab.reducer;
