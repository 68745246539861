import React from 'react';
import classnames from 'classnames';
import HeadsetIcon from '@material-ui/icons/Headset';
const AudioIcon = ({ className, ...props }) => (
  <HeadsetIcon
    fontSize="medium"
    style={{ fontSize: '20px', background: '#75D180', padding: '4px', borderRadius: '4px', fill: 'black' }}
    className={classnames({
      icon: true,
      [className]: !!className
    })}
    {...props}
  />
);

export default AudioIcon;
