import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import ForbiddenPage from './ForbiddenPage';

const PrivateRoute = ({ roles, ...props }) => {
  const {
    auth: { currentUser }
  } = useSelector(store => store);

  if (!currentUser || !roles.includes(currentUser.role)) {
    return (
      <Route {...props}>
        <ForbiddenPage />
      </Route>
    );
  }

  return <Route {...props} />;
};

export default PrivateRoute;
