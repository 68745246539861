import { createSlice, current } from '@reduxjs/toolkit';

import { fetchPages, fetchDecisions, fetchHotspots, fetchLayers, fetchItems, fetchFrames } from '../thunks/pages';
import { fetchStoryTreeById } from '../thunks/stories';
import { checkFrameInWindow } from '../../helpers/helpers';

const initialState = {
  decisions: [],
  hotspots: [],
  layers: [],
  speechbubbles: [],
  items: [],
  pages: [],
  frames: [],
  currentPageIndex: 0,
  currentFrame: null,
  currentPage: null,
  currentLayer: null,
  currentStory: null,
  isDecisionsFetching: false,
  isHotspotsFetching: false,
  isPagesFetching: false,
  isFramesFetching: false,
  isAudioFading: false,
  lastDecision: null,
  framesHistory: [],
  gamesStatus: []
};

export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setPages: (state, action) => {
      state.pages = action.payload;
    },
    setFrames: (state, action) => {
      state.frames = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
      state.currentPageIndex = state.pages.findIndex(p => p.id === action.payload.id);
    },
    setCurrentFrame: (state, action) => {
      state.currentFrame = action.payload;
      checkFrameInWindow(action.payload);
    },
    setCurrentLayer: (state, action) => {
      state.currentLayer = action.payload;
    },
    setGamesStatus: (state, action) => {
      state.gamesStatus = action.payload;
    },
    setPagesFetchingStatus: (state, action) => {
      state.isPagesFetching = action.payload;
    },
    setFramesFetchingStatus: (state, action) => {
      state.isFramesFetching = action.payload;
    },
    setDecisions: (state, action) => {
      state.decisions = action.payload;
    },
    setHotspots: (state, action) => {
      state.hotspots = action.payload;
    },
    pushHistoryItem: (state, action) => {
      state.framesHistory.push(action.payload);
    },
    popHistoryItem: state => {
      state.framesHistory.pop();
    },
    clearHistory: state => {
      state.framesHistory = [];
    },
    resetStore: state => {
      Object.keys(initialState).forEach(key => (state[key] = initialState[key]));
    },
    restartStory: state => {
      state.currentPage = state.pages[0];
      state.currentPageIndex = 0;
      const frame = state.frames[state.pages[0].id][0];
      state.currentFrame = frame;
      state.currentLayer = state.speechbubbles.find(l => l.originFrame === frame.id && l.order === 1);

      state.framesHistory = [{ page: state.pages[0], frame: state.frames[state.pages[0].id][0] }];
    },
    setLastDecision: (state, action) => {
      state.lastDecision = action.payload;
    },
    goToBackDecision: (state, action) => {
      const currentPage = state.pages.find(p => p.id === action.payload.originPage);
      const currentFrame = state.frames[currentPage.id].find(f => f.id === action.payload.originFrame);

      state.currentPage = currentPage;
      state.currentPageIndex = state.pages.findIndex(p => p.id === currentPage.id);
      state.currentFrame = currentFrame;
      state.currentLayer = state.speechbubbles.find(l => l.originFrame === currentFrame.id && l.order === 1);

      state.framesHistory.push({ page: currentPage, frame: currentFrame });
      state.lastDecision = initialState.lastDecision;
    },
    goBackToPage: (state, action) => {
      const frames = current(state.frames);
      const currentPage = current(state.currentPage);
      const currentFrame = current(state.currentFrame);
      const speechbubbles = current(state.speechbubbles);
      const pages = current(state.pages);
      const nextFrame = frames[currentPage.id].find(v => v.order === currentFrame.order - 1);
      if (!nextFrame) {
        const nextPage = pages.find(page => page.order === currentPage.order - 1 && frames[page.id]?.length);
        if (nextPage) {
          const maxOrderFrame = Math.max(...frames[nextPage.id].map((frame, index) => frame.order ?? index));
          const lastFrame = frames[nextPage.id].find((frame, index) => (frame.order ?? index) === maxOrderFrame);

          const maxOrderLayer = Math.max(
            ...speechbubbles
              .filter(bubble => bubble.originFrame === lastFrame?.id)
              .map((bubble, index) => bubble.order ?? index)
          );

          const lastLayer = speechbubbles.find(
            (layer, index) => (layer.order ?? index) === maxOrderLayer && layer.originFrame === lastFrame.id
          );
          state.currentFrame = lastFrame;
          state.currentPage = nextPage;
          state.currentLayer = lastLayer;
          state.currentPageIndex = state.pages.findIndex(p => p.id === nextPage.id);
          return;
        }
      }
      const maxOrderLayer = Math.max(
        ...speechbubbles
          .filter(bubble => bubble.originFrame === nextFrame?.id)
          .map((bubble, index) => bubble.order ?? index)
      );
      const lastLayer = speechbubbles.find(
        (layer, index) => (layer.order ?? index) === maxOrderLayer && layer.originFrame === nextFrame.id
      );

      state.currentLayer = lastLayer;
      state.currentFrame = nextFrame;
      state.currentPageIndex = 0;
      state.framesHistory.pop();
    },
    setIsAudioFading: (state, action) => {
      state.isAudioFading = action.payload;
    },
    setFullScreen: (state, action) => {
      state.currentFrame.fullScreen = action.payload;
    }
  },
  extraReducers: {
    [fetchFrames.pending]: state => {
      state.isFramesFetching = true;
    },
    [fetchFrames.fulfilled]: (state, action) => {
      const frames = action.payload;
      state.frames = [];

      frames.forEach(frame => {
        if (frame.originPage)
          if (frame.originPage in state.frames) state.frames[frame.originPage].push(frame);
          else state.frames[frame.originPage] = [frame];
      });
      state.isFramesFetching = false;
    },
    [fetchPages.pending]: state => {
      state.isPagesFetching = true;
    },
    [fetchPages.fulfilled]: (state, action) => {
      const pages = action.payload;

      state.pages = pages;
      state.isPagesFetching = false;

      if (action.payload && action.payload.length) {
        state.currentPage = pages[0];
        state.currentFrame = state.frames[pages[0].id][0];
      }
    },

    [fetchDecisions.pending]: state => {
      state.isDecisionsFetching = true;
    },
    [fetchDecisions.fulfilled]: (state, action) => {
      state.decisions = action.payload;
      state.isDecisionsFetching = false;
    },
    [fetchHotspots.pending]: state => {
      state.isHotspotsFetching = true;
    },
    [fetchHotspots.fulfilled]: (state, action) => {
      state.hotspots = action.payload.map(({ _id, ...h }) => ({ ...h, id: h.id || _id }));
      state.isHotspotsFetching = false;
    },
    [fetchLayers.fulfilled]: (state, action) => {
      state.layers = action.payload;
      const bubbles = action.payload.filter(l => l.type === 'layer_speechbubble');
      state.speechbubbles = bubbles;
    },
    [fetchItems.fulfilled]: (state, action) => {
      state.items = action.payload;
    },
    [fetchStoryTreeById.pending]: state => {
      state.isPagesFetching = true;
    },
    [fetchStoryTreeById.fulfilled]: (state, action) => {
      let filteredChaptersWithPages = action.payload?.map(item => item.type === 'chapter' && item.children);
      let filteredPages = filteredChaptersWithPages?.map((item, idx) =>
        !item && action.payload[idx].type === 'page' ? action.payload[idx] : item
      );
      filteredPages = filteredPages?.flat();

      filteredPages.forEach((page, i) => (page.order = i));
      state.pages = filteredPages;
      if (action.payload && action.payload.length) {
        state.currentPage = filteredPages[0];
        const firstFrame = state.frames[filteredPages[0].id][0];
        state.currentFrame = firstFrame;
        state.currentLayer = state.speechbubbles.find(l => l.originFrame === firstFrame.id && l.order === 1);
        state.framesHistory = [{ page: filteredPages[0], frame: state.frames[filteredPages[0].id][0] }];
      }
      state.isPagesFetching = false;
    }
  }
});

export const {
  setPages,
  setFrames,
  setCurrentPage,
  setCurrentFrame,
  setCurrentLayer,
  setPagesFetchingStatus,
  setDecisions,
  setHotspots,
  pushHistoryItem,
  setGamesStatus,
  popHistoryItem,
  clearHistory,
  resetStore,
  restartStory,
  setLastDecision,
  goToBackDecision,
  setIsAudioFading,
  setFullScreen,
  goBackToPage
} = pagesSlice.actions;

export default pagesSlice.reducer;
