import axios from 'axios';
import qs from 'query-string';

export const getNotepad = async query => {
  try {
    const response = await axios.get(qs.stringifyUrl({ url: '/notepads', query: { ...query, limit: 100 } }));
    return response.data;
  } catch (error) {
    console.log('error', error);
  }
};

export const getNotepadForTeacher = async query => {
  try {
    const response = await axios.get(qs.stringifyUrl({ url: '/notepads/teacher', query: { ...query, limit: 100 } }));
    return response.data;
  } catch (error) {
    console.log('error', error);
  }
};

export const updateNotepad = async (id, note) => {
  const res = await axios.put(`/notepads/${id}`, note);

  return res.data;
};
