import axios from 'axios';

export const getUsers = async query => {
  const res = await axios.get('/users', { params: query });

  return res.data;
};

export const getStudents = async query => {
  const res = await axios.get('/users/students', { params: query });

  return res.data;
};

export const getUserById = async (id, query) => {
  const res = await axios.get(`/users/${id}`, { params: query });

  return res.data;
};

export const loginFeide = async ({ code }) => {
  const res = await axios.post(`/users/feide-login`, { code });

  return res.data;
};

export const getCurrentUser = async () => {
  try {
    const res = await axios.get('/users/me');
    return res.data;
  } catch (err) {
    return null;
  }
};

export const updateCurrentUser = async (id, body) => {
  try {
    const res = await axios.put(`/users/${id}`, body);
    return res.data;
  } catch (err) {
    return null;
  }
};

export const updateCurrentUserHistory = async body => {
  try {
    const res = await axios.put('/users/update-history', body);
    return res.data;
  } catch (err) {
    return null;
  }
};

export const signOut = async () => {
  const res = await axios.delete('/users/logout');

  return res.data;
};
