import React from 'react';
import classnames from 'classnames';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const FileIcon = ({ className, ...props }) => (
  <InsertDriveFileIcon
    style={{ padding: '3px', background: '#3AC6E0', borderRadius: '4px', fontSize: '18px' }}
    className={classnames({
      icon: true,
      [className]: !!className
    })}
    {...props}
  />
);

export default FileIcon;
