import axios from 'axios';

export const setTokenToAxios = token => (axios.defaults.headers.Authorization = `Bearer ${token}`);

export const setTokenToStorage = token => {
  localStorage.setItem('token', token);
};

export const getTokenFromStorage = () => localStorage.getItem('token');

export const removeTokenFromStorage = () => localStorage.removeItem('token');
export const removeTokenFromAxios = () => (axios.defaults.headers.Authorization = '');
