import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAssignmentById, getAssignments } from '../../services/assignments';
import { getBackpacks } from '../../services/backpacks';
import { getNotepad, getNotepadForTeacher } from '../../services/notepads';

export const fetchAssignments = createAsyncThunk('assignments/fetchAssignments', async ({ query }, thunkApi) => {
  const { rows } = await getAssignments({ query });
  return rows;
});

export const fetchAssignmentById = createAsyncThunk('assignments/fetchAssignmentById', async id => {
  const res = await getAssignmentById(id);
  return res;
});

export const fetchAssignmentBackpack = createAsyncThunk(
  'assignments/fetchAssignmentBackpack',
  async (query, thunkApi) => {
    const {
      assignments: { currentAssignment },
      auth: { currentUser }
    } = thunkApi.getState();
    const { rows } = await getBackpacks({
      user: currentUser?.id || query.user,
      assignment: currentAssignment?.id || query.assignment,
      ...query
    });
    return rows;
  }
);

export const fetchAssignmentNotepad = createAsyncThunk(
  'assignments/fetchAssignmentNotepad',
  async (query, thunkApi) => {
    const {
      assignments: { currentBackpack },
      stories: { currentStory }
    } = thunkApi.getState();
    if (query && 'student' in query && typeof query.student === 'string') {
      const res = await getNotepadForTeacher({
        story: query.story,
        backpack: query.backpack,
        student: query.student
      });
      return res;
    } else {
      const res = await getNotepad({
        story: currentStory?.id || query.story,
        backpack: currentBackpack?.id || query.backpack
      });
      return res.notepad;
    }
  }
);
