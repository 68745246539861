import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSafari } from '../../helpers';

import { getGameResultsItem, getGameResultsItemForPreview } from '../../services/games';
import { updateBackpack } from '../../services/backpacks';
import { clearObject } from '../../utils/clearObject';

import { setNewBackpackItemsCount, setPreviewBackpack } from '../../redux/slices/assignments';
import { fetchAssignmentBackpack } from '../../redux/thunks/assignments';
import { setFullScreen, setGamesStatus } from '../../redux/slices/pages';
import { setPause } from '../../redux/slices/mediaControl';

import Button from '../Button/Button';

import './frameAttachment.scss';
import VideoAttachment from './VideoAttachment/VideoAttachment';
import { setGlobalStyle } from '../../helpers/helpers';
import { STYLE_VARS } from '../../constants';

export const FRAME_ATTACHMENT_TYPES = {
  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
  URL: 'url',
  GAME: 'game'
};

const configToString = config =>
  Object.keys(config)
    .reduce((acc, key) => [...acc, `${key}=${config[key]}`], [])
    .join(',');

const FrameAttachment = ({
  additionalAttachmentInfo,
  mobileAttachmentInfo,
  attachmentInfo,
  setSentUserId,
  windowHeight,
  setPageClick,
  windowWidth,
  videoImage,
  sentUserId,
  attachment,
  isCurrent,
  newWindow,
  autoPlay,
  title,
  id,
  isMobile,
  next,
  ...frame
}) => {
  const {
    assignments: { currentBackpack, newBackpackItemsCount, previewBackpack },
    mediaControl: { audioVolume, mute, pause },
    stories: { currentStory, currentLang },
    pages: { gamesStatus },
    navigation: { direction }
  } = useSelector(store => store);

  const currentAttachmentInfo = useMemo(
    () => (isMobile && mobileAttachmentInfo ? mobileAttachmentInfo : attachmentInfo),
    [isMobile, attachmentInfo, mobileAttachmentInfo]
  );

  const [isSafariFullScreen, setIsSafariFullScreen] = useState(false);
  const video = useRef();
  const dispatch = useDispatch();
  const openWindow = (title, attachment, windowWidth = 800, windowHeight = 600) => {
    const { width: sw, height: sh } = window.screen;
    window.open(
      attachment,
      title,
      configToString({
        toolbar: 'no',
        location: 'no',
        directories: 'no',
        status: 'no',
        menubar: 'no',
        scrollbars: 'yes',
        resizable: 'yes',
        width: windowWidth,
        height: windowHeight,
        top: sh / 2 - windowHeight / 2,
        left: sw / 2 - windowWidth / 2
      })
    );
  };

  const tracks = useMemo(() => video.current && video.current.textTracks, [video]);

  const isFocus = useMemo(
    () => isCurrent && frame?.animation?.focus && !!frame?.animation?.focusSpeed,
    [isCurrent, frame]
  );

  const isGameEnded = useMemo(
    () => (currentAttachmentInfo?.type === 'game' ? gamesStatus.find(item => item.id === id)?.isGameEnded : true),
    [currentAttachmentInfo, gamesStatus, id]
  );

  const audioRef = useRef();

  useEffect(() => {
    if (!audioRef.current) return;

    if (mute) {
      audioRef.current.volume = 0.0;
    }

    if (!mute && audioVolume) {
      audioRef.current.volume = audioVolume / 100;
    }
  }, [mute, audioVolume]);

  // useEffect(() => {
  //   if (!video.current) {
  //     return;
  //   }
  //   if (isCurrent) {
  //     if (pause) {
  //       video.current.pause();
  //     } else {
  //       video.current.play();
  //     }
  //   }
  // }, [isCurrent, pause]);

  useEffect(() => {
    if (!video.current) return;

    if (mute) {
      video.current.volume = 0.0;
    }

    if (audioVolume) {
      video.current.volume = audioVolume / 100;
    }
  }, [mute, audioVolume]);

  // useEffect(() => {
  //   if (!video.current) {
  //     return;
  //   }

  //   if (mute) {
  //     video.current.volume = 0;
  //   }

  //   if (!mute && audioVolume) {
  //     video.current.volume = audioVolume / 100;
  //   }

  //   if (!isCurrent) {
  //     dispatch(setPause(true));
  //     video.current.pause();
  //     video.current.load();
  //     return;
  //   }
  // }, [isCurrent, video, mute, audioVolume, dispatch]);

  // useEffect(() => {
  //   if (autoPlay && video.current) {
  //     dispatch(setPause(false));
  //     video.current.play();
  //   }
  // }, [autoPlay, dispatch]);

  useEffect(() => {
    if (!video.current) {
      return;
    }

    if (isCurrent) {
      if (pause && !video.current.paused) {
        dispatch(setPause(true));
        video.current.pause();
      } else if (!pause && video.current.paused) {
        dispatch(setPause(false));
        video.current.play().catch(err => console.log(err));
      }
    }
  }, [isCurrent, pause, video, dispatch]);

  useEffect(() => {
    if (!video.current) {
      return;
    }

    if (mute) {
      video.current.volume = 0;
    } else if (audioVolume) {
      video.current.volume = audioVolume / 100;
    }

    if (!isCurrent) {
      if (!video.current.paused) {
        dispatch(setPause(true));
        video.current.pause();
      }
      video.current.load();
      return;
    }
  }, [isCurrent, video, mute, audioVolume, dispatch]);

  useEffect(() => {
    if (!video.current) {
      return;
    }

    if (autoPlay) {
      if (!video.current.paused) {
        dispatch(setPause(false));
        video.current.play().catch(err => console.log(err));
      } else {
        video.current.load();
        dispatch(setPause(false));
        video.current.play().catch(err => console.log(err));
      }
    }
  }, [autoPlay, dispatch, video]);

  useEffect(() => {
    isCurrent &&
      video.current &&
      video.current.addEventListener('webkitfullscreenchange', () => setIsSafariFullScreen(!isSafariFullScreen));
  }, [isCurrent, isSafariFullScreen]);

  useEffect(() => {
    if (!video.current && !isCurrent) {
      return;
    }

    tracks &&
      Object.values(tracks).map(currentTrack => {
        if (currentTrack.language === currentLang) {
          if (currentTrack.mode === 'showing') return (currentTrack.mode = 'hidden');
          return (currentTrack.mode = 'showing');
        }
        return (currentTrack.mode = 'hidden');
      });
  }, [currentLang, dispatch, isCurrent, tracks]);

  useEffect(() => {
    if (isCurrent) {
      const iframe = document.getElementById(`game-${id}`);
      if (!iframe || currentAttachmentInfo.type !== 'game') return;
      iframe.style.display = 'block';
      handleStartGame(iframe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrent, currentAttachmentInfo.type]);

  const handleRequestFullscreen = useCallback(
    element => {
      if (element.requestFullscreen) element.requestFullscreen();
      if (element.mozRequestFullScreen) element.mozRequestFullScreen();
      if (element.webkitRequestFullScreen) element.webkitRequestFullScreen();
      if (element.msRequestFullscreen) element.msRequestFullscreen();
      // eslint-disable-next-line no-restricted-globals
      if (isMobile) screen.orientation.lock('landscape');
    },
    [isMobile]
  );

  const handleCancelFullscreen = useCallback(() => {
    if (document.exitFullscreen) document.exitFullscreen();
    if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
    if (document.msExitFullScreen) document.msExitFullScreen();
    // eslint-disable-next-line no-restricted-globals
    screen.orientation.unlock();
    // eslint-disable-next-line
  }, []);

  document.addEventListener('fullscreenchange', () => {
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      const iframe = document.getElementById(`game-${id}`);
      if (iframe) iframe.style.display = 'none';
      // eslint-disable-next-line no-restricted-globals
      screen.orientation.unlock();
      window.focus();
    }
  });

  useEffect(() => {
    const currentFrame = document.getElementById(`currentImage-${id}`);
    if (!currentFrame) return;

    if (isFocus) {
      const docStyle = document.documentElement.style;
      const zoomIn = 1 + (frame.animation.zoomIn || 15) / 100;
      setGlobalStyle(docStyle, STYLE_VARS.KEN_BURNS_ZOOM_IN, zoomIn);
      let animationName = frame.animation.focus;

      if (frame.animation.focus === 'zoomIn') animationName = 'focusZoomIn';
      if (frame.animation.focus === 'zoomOut') animationName = 'focusZoomOut';

      const delay = frame.animation.duration || 0;
      setTimeout(() => {
        currentFrame.style.animation = `${animationName && animationName} ${
          frame.animation.focusSpeed || 10
        }s forwards`;
      }, delay * 1000);
    }
    if (direction === 'back') {
      currentFrame.style.animation = '';
    }
    // else {
    //   currentFrame.style.animationPlayState = 'paused';
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFocus,
    frame.animation.zoomIn,
    id,
    frame.animation.focus,
    frame.animation.duration,
    frame.animation.focusSpeed
  ]);

  const handleGameEnd = useCallback(
    async event => {
      if (event.data !== true) return;
      if (next) {
        next({ automated: false });
      }
      const timer = setTimeout(async () => {
        const iframe = document.getElementById(`game-${id}`);
        if (iframe) iframe.style.display = 'none';
        handleCancelFullscreen();
        window.focus();
        window.removeEventListener('message', handleGameEnd);
        dispatch(setGamesStatus([...gamesStatus, { id, isGameEnded: true }]));
        setSentUserId(false);
        const item = currentBackpack?.id
          ? await getGameResultsItem(currentStory.id, currentAttachmentInfo.key)
          : await getGameResultsItemForPreview(currentStory.id, id, currentAttachmentInfo.key);
        if (!item) return;
        if (currentBackpack) await updateBackpack(currentBackpack.id, { items: [...currentBackpack.items, item.id] });
        else
          dispatch(
            setPreviewBackpack({
              id: previewBackpack.id,
              items: [...previewBackpack.items, item.id],
              itemsNotes: [...previewBackpack.itemsNotes],
              hotspotNotes: [...previewBackpack.hotspotNotes]
            })
          );
        dispatch(fetchAssignmentBackpack());
        dispatch(setNewBackpackItemsCount(newBackpackItemsCount + 1));
      }, 500);
      return () => clearTimeout(timer);
    },
    // eslint-disable-next-line
    [
      handleCancelFullscreen,
      newBackpackItemsCount,
      currentBackpack,
      currentStory.id,
      previewBackpack,
      currentAttachmentInfo,
      setSentUserId,
      gamesStatus,
      dispatch,
      id
    ]
  );

  const handleStartGame = useCallback(() => {
    const iframe = document.getElementById(`game-${id}`);
    if (iframe) {
      iframe.style.display = 'block';
    }
    window.addEventListener('message', handleGameEnd);
    handleRequestFullscreen(iframe);
    iframe.focus();
    setSentUserId(true);
  }, [setSentUserId, handleRequestFullscreen, id, handleGameEnd]);

  const handleVideoEnded = useCallback(() => {
    dispatch(setFullScreen(false));
    dispatch(setPause(true));
  }, [dispatch]);

  const onKenBurnsAnimationEnd = frame => {
    if (isFocus) {
      let prop = frame?.animation?.focus.replace(/([A-Z])/g, ' $1').split(' ');

      if (frame.animation.focus === 'zoomIn' || frame.animation.focus === 'zoomOut') return;
      const zoomIn = 1 + (frame.animation.zoomIn || 15) / 100;

      const currentFrame = document.getElementById(`currentImage-${id || frame.originalFrame}`);
      currentFrame.style.animation = ``;
      currentFrame.style.transformOrigin = `${prop[1].toLowerCase()} ${prop[0]}`;
      currentFrame.style.transform = `scale(${zoomIn})`;
    }
  };

  const handleKeyDown = event => {
    if (event.keyCode === 32) {
      event.preventDefault();
      if (video.current.paused) {
        video.current.play();
      } else {
        video.current.pause();
      }
    }
  };

  const subtitles = useMemo(() => clearObject(currentAttachmentInfo?.subtitles), [currentAttachmentInfo]);

  switch (currentAttachmentInfo?.type) {
    case FRAME_ATTACHMENT_TYPES.IMAGE:
      return (
        <div
          className="img-wrap"
          style={{
            borderRadius: frame.isCircle && '50%',
            WebkitBorderRadius: frame.isCircle && '50%',
            WebkitMaskImage: '-webkit-radial-gradient(white, black)'
          }}
        >
          <img
            onAnimationEnd={() => onKenBurnsAnimationEnd(frame)}
            id={`currentImage-${id || frame.originalFrame}`}
            className="attachment img"
            src={currentAttachmentInfo.url}
            alt="cover"
          />
        </div>
      );
    case FRAME_ATTACHMENT_TYPES.VIDEO:
      return (
        <div className="video-container">
          {isSafari() && videoImage?.url && !isCurrent && (
            <img
              style={{ zIndex: frame.zIndex + 1, borderRadius: frame.isCircle && '50%' }}
              alt=""
              className="video-poster"
              src={videoImage.url}
            />
          )}
          <VideoAttachment
            isSafari={isSafari}
            videoImage={videoImage}
            isCurrent={isCurrent}
            autoPlay={autoPlay}
            attachmentInfo={currentAttachmentInfo}
            isCircle={frame.isCircle}
            subtitles={subtitles}
            handleVideoEnded={handleVideoEnded}
            createdAt={frame.createdAt}
            videoRef={video}
            currentLanguage={currentLang}
            handleKeyDown={handleKeyDown}
          />
        </div>
      );
    case FRAME_ATTACHMENT_TYPES.URL:
      return (
        <>
          {!!newWindow === true ? (
            <>
              {currentAttachmentInfo && (
                <img
                  className={'attachment img'}
                  src={currentAttachmentInfo.url}
                  style={{ cursor: 'pointer' }}
                  alt={''}
                  onClick={() => openWindow(title, currentAttachmentInfo.url, windowWidth, windowHeight)}
                />
              )}
            </>
          ) : (
            <>
              <iframe
                src={currentAttachmentInfo.url}
                style={{ height: '100%', width: '100%', borderRadius: frame.isCircle && '50%' }}
                referrerPolicy="origin-when-cross-origin"
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-storage-access-by-user-activation"
                allow={`${autoPlay ? 'autoplay' : ''} fullscreen`}
                allowFullScreen
                title="iframe"
                className="iframe"
              />
            </>
          )}
        </>
      );
    case FRAME_ATTACHMENT_TYPES.AUDIO:
      return <audio ref={audioRef} autoPlay={autoPlay} type="audio/mpeg" loop={currentAttachmentInfo.loopAudio} />;
    case FRAME_ATTACHMENT_TYPES.GAME: {
      return (
        <>
          <div className="game-placeholder">
            <span className="game-placeholder-text"> {currentAttachmentInfo.name}</span>
            {!isGameEnded && isCurrent && (
              <Button className="start-game-button" onClick={handleStartGame}>
                Enter game
              </Button>
            )}
          </div>
        </>
      );
    }
    default:
      return null;
  }
};

export default FrameAttachment;
