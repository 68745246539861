import React from 'react';
import classNames from 'classnames';
import { getEnglishName } from 'all-iso-language-codes';

const VideoAttachment = ({
  isSafari,
  videoImage,
  isCurrent,
  autoPlay,
  attachmentInfo,
  isCircle,
  subtitles,
  handleVideoEnded,
  createdAt,
  videoRef,
  currentLanguage,
  handleKeyDown
}) => {
  return (
    <video
      className={classNames('attachment-video', { 'not-current': !isCurrent }, { 'is-circle-frame': isCircle })}
      crossOrigin="anonymous"
      src={isSafari() ? `${attachmentInfo.url}#t=0.1` : attachmentInfo.url}
      {...(videoImage?.url && { poster: videoImage.url })}
      controls={isCurrent}
      autoPlay={!!autoPlay}
      preload="metadata"
      id={`${createdAt}-video`}
      onEnded={handleVideoEnded}
      ref={videoRef}
      style={{ borderRadius: isCircle && '50%' }}
      playsInline
      onKeyDown={handleKeyDown}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      {Object.entries(subtitles).map(([key, value]) => (
        <track
          key={key}
          style={{ fontSize: '10px' }}
          kind="subtitles"
          label={getEnglishName(key)}
          srcLang={key}
          src={value.url}
          default={currentLanguage === key}
        />
      ))}
    </video>
  );
};

export default VideoAttachment;
