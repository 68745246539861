import axios from 'axios';

export const getGameResults = async (storyId, studentId, gameKey) => {
  try {
    const response = await axios.get(`game/results/${storyId}`, { params: { uId: studentId, game: gameKey } });
    return response.data;
  } catch (error) {
    console.log('error', error);
  }
};

export const getGameResultsItem = async (storyId, gameKey) => {
  try {
    const response = await axios.get(`game/results/${storyId}`, { params: { game: gameKey } });
    return response.data.item;
  } catch (error) {
    console.log('error', error);
  }
};

export const getGameResultsItemForPreview = async (storyId, randomUId, gameKey) => {
  try {
    const response = await axios.get(`game/results/${storyId}`, { params: { uId: randomUId, game: gameKey } });
    return response.data.item;
  } catch (error) {
    console.log('error', error);
  }
};
