import React from 'react';
import classnames from 'classnames';
import VideoLibraryRoundedIcon from '@material-ui/icons/VideoLibraryRounded';

const VideoIcon = ({ className, ...props }) => (
  <VideoLibraryRoundedIcon
    style={{
      fontSize: '20px',
      fill: 'white',
      borderRadius: '4px',
      padding: '5px',
      backgroundColor: '#FC2472'
    }}
    className={classnames({
      icon: true,
      [className]: !!className
    })}
    {...props}
  />
);

export default VideoIcon;

/* <span style={{ background: '#FC2472', borderRadius: '4px', padding: '0px 4.5px', width: '20px' }}>
      <PlayArrowRoundedIcon
        style={{
          fontSize: '10px',
          fill: 'white',
          borderRadius: '50%',
          padding: '1.5px',
          backgroundColor: '#ff81a9'
        }}
        className={classnames({
          icon: true,
          [className]: !!className
        })}
        {...props}
      />
    </span> */
