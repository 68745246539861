import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'querystring';
import { ToastContainer } from 'react-toastify';

import { omitEmptyKeys } from '../../helpers';

import { getTokenFromStorage, setTokenToAxios } from '../../utils/auth';

import { getCurrentUser } from '../../services/users';

import { setCurrentUser } from '../../redux/slices/auth';
import { setCurrentLanguage } from '../../redux/slices/stories';

import { ROLES } from '../../constants';

import './app.scss';

import 'react-toastify/dist/ReactToastify.css';
import { getLocalStorage } from '../../utils/userService';
import { lazy } from 'react';
import Loader from '../../components/Loader/Loader';
import PrivateRoute from '../../components/PrivateRoute';
import { PopUp } from '../PopUp/PopUp';

const Help = lazy(() => import('../Help/Help'));
const View = lazy(() => import('../View/View'));
const Home = lazy(() => import('../Home/Home'));
const Student = lazy(() => import('../Student'));
const SignIn = lazy(() => import('../SignIn/SignIn'));
const Teacher = lazy(() => import('../Teacher/Home'));
const Preview = lazy(() => import('../Preview/Preview'));
const CreateAssignment = lazy(() => import('../Assignments/Create'));
const StoryDetails = lazy(() => import('../StoryDetails/StoryDetails'));
const AutoAssignment = lazy(() => import('../Assignments/AutoCreation'));
const WriteReview = lazy(() => import('../Teacher/WriteReview/WriteReview'));
const StudentDetails = lazy(() => import('../Teacher/StudentDetails/StudentDetails'));
const StoryCarousel = lazy(() => import('../../components/StoryCarousel/StoryCarousel'));
const TeacherAssignmentDetails = lazy(() => import('../Teacher/AssignmentDetails/AssignmentDetails'));
const StudentAssignmentDetails = lazy(() => import('../Student/StudentAssignmentDetails/StudentAssignmentDetails'));
const WriteNewReportComponent = lazy(() => import('../Student/WriteReportComponent/WriteNewReport/WriteNewReport'));

// TODO refactor routes
const ROUTES = {
  HOME: '/',
  ASSIGNMENT: '/assignment',
  TEACHER: '/teacher',
  STUDENT: '/student',
  ASSIGNMENT_REPORT: '/assignments/:assignmentId/report',
  ASSIGNMENT_DETAILS: '/assignment/:assignmentId',
  STORY_DETAILS: '/teacher/stories/:storyId/details',
  CREATE_ASSIGNMENT: '/assignments/new',
  STORY_VIEW: '/play/:storyId/:assignmentId',
  STORY_TEACHER_VIEW: '/play/:storyId',
  STORY_PREVIEW: '/preview',
  STUDENT_PREVIEW: '/student_preview',
  TEACHER_ASSIGNMENT_DETAILS: '/teacher/assignment-details/:assignmentId',
  TEACHER_STUDENT_DETAILS: '/teacher/student-details/:studentId',
  TEACHER_WRITE_REVIEW: '/teacher/reviews/new',
  SIGN_IN: '/sign-in',
  HELP: 'https://docs.google.com/document/d/1gREzV72498BkLQcBw6khCb6LcNSChRvIdEH4y9Og1Z4/edit?usp=sharing',
  FEEDBACK: 'https://forms.monday.com/forms/764db7114d6566576fe4edb9aeb5d832?r=use1',
  STORY_LINK: '/storylink/:assignmentId',
  POP_UP: '/popup'
};

const routeGetters = {
  assignmentDetails: id => ROUTES.ASSIGNMENT_DETAILS.replace(':assignmentId', id),
  assignmentReport: id => ROUTES.ASSIGNMENT_REPORT.replace(':assignmentId', id),
  storyDetails: id => ROUTES.STORY_DETAILS.replace(':storyId', id),
  storyView: (storyId, assignmentId) =>
    ROUTES.STORY_VIEW.replace(':storyId', storyId).replace(':assignmentId', assignmentId ? assignmentId : ''),
  createAssignment: storyId => `${ROUTES.CREATE_ASSIGNMENT}?storyId=${storyId}`,
  teacherAssignmentDetails: id => ROUTES.TEACHER_ASSIGNMENT_DETAILS.replace(':assignmentId', id),
  teacherStudentDetails: id => ROUTES.TEACHER_STUDENT_DETAILS.replace(':studentId', id),
  teacherWriteReview: ({ report, review }) =>
    `${ROUTES.TEACHER_WRITE_REVIEW}?${qs.stringify(omitEmptyKeys({ reportId: report, reviewId: review }))}`,
  preview: storyId => `${ROUTES.STORY_PREVIEW}?id=${storyId}`,
  studentPreview: storyId => `${ROUTES.STUDENT_PREVIEW}?id=${storyId}`
};

const PUBLIC_ROUTES = [ROUTES.SIGN_IN, ROUTES.STORY_PREVIEW, ROUTES.STUDENT_PREVIEW, ROUTES.POP_UP];

const App = ({ ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    auth: { currentUser }
  } = useSelector(store => store);

  useEffect(() => {
    dispatch(setCurrentLanguage(getLocalStorage('lng') || 'nb'));
  }, [dispatch]);

  useEffect(() => {
    if (PUBLIC_ROUTES.includes(location.pathname)) {
      setIsLoading(false);
      return;
    }

    if (currentUser) return;

    if (location.pathname === '/sign-in') {
      setIsLoading(false);
      return;
    }

    const storageToken = getTokenFromStorage();

    if (!storageToken) {
      localStorage.removeItem('redirect');
      if (location.pathname.includes('storylink')) localStorage.setItem('redirect', location.pathname);
      history.push('/sign-in', { from: location.pathname });
      return;
    }

    setTokenToAxios(storageToken);

    try {
      getCurrentUser().then(user => {
        setIsLoading(false);
        if (!user) history.push('/sign-in');
        dispatch(setCurrentUser(user));
      });
    } catch (error) {
      setIsLoading(false);
      history.push('/sign-in');
    }
  }, [history, location, dispatch, currentUser]);

  if (isLoading) return <Loader full />;

  return (
    <Suspense fallback={<Loader full />}>
      <div className="app" {...props}>
        <Switch>
          <Route path={ROUTES.SIGN_IN}>
            <SignIn />
          </Route>

          <Route path={ROUTES.POP_UP}>
            <PopUp />
          </Route>

          <PrivateRoute roles={[ROLES.TEACHER, ROLES.STUDENT]} exact path={ROUTES.HOME}>
            <Home />
          </PrivateRoute>

          <PrivateRoute roles={[ROLES.TEACHER]} path={ROUTES.TEACHER} exact>
            <Teacher />
          </PrivateRoute>

          <PrivateRoute roles={[ROLES.STUDENT]} path={ROUTES.ASSIGNMENT} exact>
            <Student />
          </PrivateRoute>
          <PrivateRoute roles={[ROLES.STUDENT]} path={ROUTES.STUDENT} exact>
            <StoryCarousel />
          </PrivateRoute>

          <PrivateRoute roles={[ROLES.TEACHER]} path={ROUTES.STORY_DETAILS} exact>
            <StoryDetails />
          </PrivateRoute>

          <PrivateRoute roles={[ROLES.STUDENT]} path={ROUTES.ASSIGNMENT_DETAILS} exact>
            <StudentAssignmentDetails />
          </PrivateRoute>

          <PrivateRoute roles={[ROLES.STUDENT]} path={ROUTES.ASSIGNMENT_REPORT} exact>
            <WriteNewReportComponent />
          </PrivateRoute>

          <PrivateRoute roles={[ROLES.TEACHER]} path={ROUTES.CREATE_ASSIGNMENT} exact>
            <CreateAssignment />
          </PrivateRoute>

          <PrivateRoute roles={[ROLES.TEACHER]} path={ROUTES.TEACHER_ASSIGNMENT_DETAILS} exact>
            <TeacherAssignmentDetails />
          </PrivateRoute>

          <PrivateRoute roles={[ROLES.TEACHER]} path={ROUTES.TEACHER_STUDENT_DETAILS} exact>
            <StudentDetails />
          </PrivateRoute>

          <PrivateRoute roles={[ROLES.TEACHER]} path={ROUTES.TEACHER_WRITE_REVIEW} exact>
            <WriteReview />
          </PrivateRoute>

          <PrivateRoute roles={[ROLES.TEACHER, ROLES.STUDENT]} path={ROUTES.HELP} exact>
            <Help />
          </PrivateRoute>

          <Route path={ROUTES.STORY_PREVIEW} exact>
            <Preview />
          </Route>

          <Route path={ROUTES.STUDENT_PREVIEW} exact>
            <Preview />
          </Route>

          <PrivateRoute roles={[ROLES.STUDENT]} path={ROUTES.STORY_VIEW} exact>
            <View />
          </PrivateRoute>

          <PrivateRoute roles={[ROLES.TEACHER, ROLES.STUDENT]} path={ROUTES.STORY_TEACHER_VIEW} exact>
            <View />
          </PrivateRoute>

          <PrivateRoute roles={[ROLES.STUDENT]} path={ROUTES.STORY_LINK} exact>
            <AutoAssignment />
          </PrivateRoute>

          <Route>Not Found</Route>
        </Switch>

        <div className="background" />

        <ToastContainer />
      </div>
    </Suspense>
  );
};

export { ROUTES, routeGetters };
export default App;
