import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFrames } from '../../services/frames';
import { getPages, getDecisions, getHotspots, getLayers, getItems } from '../../services/pages';

export const fetchPages = createAsyncThunk('pages/fetchPages', async query => {
  const { rows } = await getPages(query);
  return rows;
});

export const fetchFrames = createAsyncThunk('frames/fetchFrames', async query => {
  const { rows } = await getFrames({ story: query.story, sort: 'order', ...query });
  return rows;
});

export const fetchDecisions = createAsyncThunk('pages/fetchDecisions', async query => {
  const { rows } = await getDecisions({ story: query.story, ...query });

  return rows;
});

export const fetchHotspots = createAsyncThunk('pages/fetchHotspots', async query => {
  const { rows } = await getHotspots({ story: query.story, ...query });

  return rows;
});

export const fetchLayers = createAsyncThunk('pages/fetchLayers', async query => {
  const { rows } = await getLayers({ story: query.story, ...query });

  return rows;
});

export const fetchItems = createAsyncThunk('pages/fetchItems', async query => {
  const { rows } = await getItems({ story: query.story, ...query });

  return rows;
});
