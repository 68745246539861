import { createSlice } from '@reduxjs/toolkit';

import {
  fetchAssignmentById,
  fetchAssignments,
  fetchAssignmentBackpack,
  fetchAssignmentNotepad
} from '../thunks/assignments';

export const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState: {
    assignments: [],
    newBackpackItemsCount: 0,
    isAssignmentsFetching: true,
    pickButtonCoords: null,
    pickedItem: null,
    previewBackpack: {
      hotspotNotes: [],
      itemsNotes: [],
      id: 'preview',
      items: []
    },
    hotspotForNote: {}
  },
  reducers: {
    setCurrentAssignment: (state, action) => {
      state.currentAssignment = action.payload;
    },
    setAssignments: (state, action) => {
      state.assignments = action.payload;
    },
    setAssignmentsFetchingStatus: (state, action) => {
      state.isAssignmentsFetching = action.payload;
    },
    setNewBackpackItemsCount: (state, action) => {
      state.newBackpackItemsCount = action.payload;
    },
    setPickItem: (state, action) => {
      state.pickedItem = action.payload.item;
      state.pickButtonCoords = action.payload.pickButtonCoords;
    },
    setPreviewBackpack: (state, action) => {
      state.previewBackpack = action.payload;
    },
    setHotspotForNote: (state, action) => {
      state.hotspotForNote = action.payload;
    }
  },
  extraReducers: {
    [fetchAssignments.pending]: state => {
      state.isAssignmentsFetching = true;
    },
    [fetchAssignments.fulfilled]: (state, action) => {
      state.assignments = action.payload.filter(assignment => !!assignment.story);
      state.isAssignmentsFetching = false;
    },
    [fetchAssignmentById.fulfilled]: (state, action) => {
      state.assignments = [...state.assignments.filter(s => s.id !== action.payload.id), action.payload];
      state.currentAssignment = action.payload;
      state.isAssignmentsFetching = false;
    },
    [fetchAssignmentBackpack.fulfilled]: (state, action) => {
      state.currentBackpack = action.payload[0];
    },
    [fetchAssignmentNotepad.fulfilled]: (state, action) => {
      state.currentNotepad = action.payload;
    }
  }
});

export const {
  setAssignmentsFetchingStatus,
  setNewBackpackItemsCount,
  setCurrentAssignment,
  setPreviewBackpack,
  setHotspotForNote,
  setAssignments,
  setPickItem
} = assignmentsSlice.actions;

export default assignmentsSlice.reducer;
