import React from 'react';
import classnames from 'classnames';

import './button.scss';

const Button = ({ children, name, className, secondary = false, grey = false, ...props }) => {
  return (
    <button name={name} className={classnames('button', { secondary, grey, [className]: !!className })} {...props}>
      {children}
    </button>
  );
};

export default Button;
