import axios from 'axios';
import qs from 'query-string';

const defaultQueryConfig = {
  limit: 100,
  page: 1
};

export const getStories = async (queryConfig = {}) => {
  const config = {
    ...defaultQueryConfig,
    ...queryConfig,
    q: queryConfig.search || ''
  };
  const parseConfig = cfg =>
    Object.keys(cfg)
      .filter(key => config[key] !== '' && key !== 'search')
      .reduce((acc, key) => ({ ...acc, [key]: config[key] }), {});

  const url = qs.stringifyUrl({
    url: '/stories',
    query: parseConfig(config)
  });

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    // TODO handle error
    console.log('error', error);
  }
};

export const getStoryById = async (id, config) => {
  try {
    const response = await axios.get(`/stories/${id}/`, config);
    return response.data;
  } catch (error) {
    // TODO handle error
    console.log('error', error);
  }
};

export const restartStoryById = async id => {
  try {
    const response = await axios.put(`/stories/${id}/restart`);
    return response.data;
  } catch (error) {
    // TODO handle error
    console.log('error', error);
  }
};

export const getStoryTreeById = async (id, config) => {
  try {
    const response = await axios.get(`/stories/tree/${id}/`, config);
    return response.data;
  } catch (error) {
    console.log('error', error);
  }
};

export const updateStoryProgress = async data => {
  try {
    const response = await axios.post('/users/progress', data);
    return response.data;
  } catch (error) {
    console.log('error', error);
  }
};

export const getStoryProgress = async (id, config) => {
  try {
    const response = await axios.get(`/users/progress/${id}/`, config);
    return response.data;
  } catch (error) {
    console.log('error', error);
  }
};
