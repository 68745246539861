import { createSlice } from '@reduxjs/toolkit';

export const mediaControl = createSlice({
  name: 'mediaControl',
  initialState: {
    pause: false,
    mute: false,
    audioVolume: 100
  },
  reducers: {
    setMute: (state, action) => {
      state.mute = action.payload;
    },
    setPause: (state, action) => {
      state.pause = action.payload;
    },
    setAudioVolume: (state, action) => {
      state.audioVolume = action.payload;
      if (action.payload === 0) state.mute = true;
      if (action.payload > 0) state.mute = false;
    }
  }
});

export const { setMute, setAudioVolume, setPause } = mediaControl.actions;

export default mediaControl.reducer;
