import { createSlice } from '@reduxjs/toolkit';

export const navigation = createSlice({
  name: 'navigation',
  initialState: {
    direction: '',
    isInteractive: false
  },
  reducers: {
    setDirection: (state, action) => {
      state.direction = action.payload;
    },
    setInterection: (state, action) => {
      state.isInteractive = action.payload;
    }
  }
});

export const { setDirection, setInterection } = navigation.actions;

export default navigation.reducer;
