import axios from 'axios';
import qs from 'query-string';

export const getFrames = async query => {
  try {
    const response = await axios.get(qs.stringifyUrl({ url: '/frames', query: { ...query } }));
    return response.data;
  } catch (error) {
    // TODO handle error
  }
};
