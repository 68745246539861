import axios from 'axios';
import qs from 'query-string';

export const getPages = async query => {
  try {
    const response = await axios.get(qs.stringifyUrl({ url: '/pages', query: { ...query, limit: 100 } }));
    return response.data;
  } catch (error) {
    // TODO handle error
  }
};

export const getDecisions = async query => {
  try {
    const response = await axios.get(qs.stringifyUrl({ url: '/decisions', query: { ...query, limit: 100 } }));
    return response.data;
  } catch (error) {
    // TODO handle error
  }
};

export const getHotspots = async query => {
  try {
    const response = await axios.get(qs.stringifyUrl({ url: '/hotspots', query: { ...query, limit: 100 } }));
    return response.data;
  } catch (error) {
    // TODO handle error
  }
};

export const getLayers = async query => {
  try {
    const response = await axios.get(qs.stringifyUrl({ url: '/layers', query: { ...query, limit: 100 } }));
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getItems = async query => {
  try {
    const response = await axios.get(qs.stringifyUrl({ url: '/items', query: { ...query, limit: 100 } }));
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCheckpoints = async queryParams => {
  const response = await axios.get('/checkpoints', { params: queryParams });
  return response.data;
};
