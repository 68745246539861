import React, { useCallback, useEffect, useState } from 'react';
export const PopUp = () => {
  const [frameUrl, setFrameUrl] = useState('');

  const readStorage = useCallback(e => {
    const storageFormField = JSON.parse(localStorage.getItem('popup-url'));

    if (storageFormField) setFrameUrl(storageFormField);
  }, []);

  useEffect(() => {
    readStorage();

    return () => {
      localStorage.removeItem('popup-url');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function beforeUnload() {
      localStorage.removeItem('popup-url');
    }

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, []);

  return (
    <div style={{ height: '100svh', width: '100vw', overflow: 'hidden' }}>
      {!!frameUrl && (
        <iframe
          src={frameUrl}
          style={{ height: '100svh', width: '100vw' }}
          referrerPolicy="origin-when-cross-origin"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-storage-access-by-user-activation"
          title="iframe"
          className="iframe"
        />
      )}
    </div>
  );
};
