import React from 'react'

import './loader.scss'

const Loader = ({ raw = false, full = false }) => {
  const rawLoader = (
    <div className="loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
  if (raw) return rawLoader

  const fullLoader = <div className="full-loader-container">{rawLoader}</div>
  if (full) return fullLoader

  return <div className="loader-container">{rawLoader}</div>
}

export default Loader
